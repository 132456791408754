export const FEATURES = {
  ignition_access: "IGNITION_ACCESS",
  ignition_beta: "IGNITION_BETA_ACCESS",
  ignition_manual_reload: "IGNITION_MANUAL_RELOAD",
  activation_access: "ACTIVATION_ACCESS",
  activation_beta_access: "ACTIVATION_BETA_ACCESS",
  activation_email_send: "ACTIVATION_EMAIL_SEND",
  activation_email_template_edition: "ACTIVATION_EMAIL_TEMPLATE_EDITION",
  activation_contact_variables: "ACTIVATION_CONTACT_VARIABLES",
  activation_create_audience_from_csv: "ACTIVATION_CREATE_AUDIENCE_FROM_CSV",
  activation_pims_integration: "ACTIVATION_PIMS_INTEGRATION",
  activation_facebook_send: "ACTIVATION_FACEBOOK_CHANNEL",
  activation_landing_page: "ACTIVATION_LANDING_PAGE",
  activation_mediatransports_send: "ACTIVATION_MEDIATRANSPORTS_CHANNEL",
  activation_multi_optins_selection: "ACTIVATION_MULTI_OPTINS_SELECTION",
  activation_roi: "ROI_REPORT",
  activation_service_action: "ACTIVATION_SERVICE_ACTION",
  activation_sms_send: "ACTIVATION_SMS_SEND",
  activation_sending_stats_export: "ACTIVATION_SENDING_STATS_EXPORT",
  relation_access: "RELATION_ACCESS",
  relation_beta_access: "RELATION_BETA_ACCESS",
  relation_contact_variables: "RELATION_CONTACT_VARIABLES",
  relation_landing_page_trigger: "RELATION_LANDING_PAGE_TRIGGER",
  relation_multi_optins_selection: "RELATION_MULTI_OPTINS_SELECTION",
  relation_sms_send: "RELATION_SMS_SEND"
};

export const ROLES = {
  admin: { key: "ADMINISTRATOR", icon: "logo-delight.png" },
  user: { key: "USER", icon: "logo-delight.png" },
  activation_user: {
    key: "ACTIVATION_USER",
    icon: "logo-activation.png",
    features: Object.keys(FEATURES).filter(key => key.includes("activation"))
  },
  ignition_user: {
    key: "IGNITION_USER",
    icon: "logo-ignition.png",
    features: Object.keys(FEATURES).filter(key => key.includes("ignition"))
  },
  inspiration_user: {
    key: "INSPIRATION_USER",
    icon: "logo-inspiration.png",
    features: Object.keys(FEATURES).filter(key => key.includes("inspiration"))
  },
  satisfaction_user: {
    key: "SATISFACTION_USER",
    icon: "logo-satisfaction.png",
    features: Object.keys(FEATURES).filter(key => key.includes("satisfaction"))
  },
  relation_user: {
    key: "RELATION_USER",
    icon: "logo-satisfaction.png",
    features: Object.keys(FEATURES).filter(key => key.includes("relation"))
  }
};

export const QUOTAS = {
  activation_email_send: {
    quotaIcon: "envelope",
    productIcon: "logo-activation.png",
    labelKey: "EMAILS_QUOTA"
  },
  activation_sms_send: {
    quotaIcon: "sms",
    productIcon: "logo-activation.png",
    labelKey: "SMS_QUOTA"
  },
  relation_email_send: {
    quotaIcon: "envelope",
    productIcon: "logo-satisfaction.png",
    labelKey: "EMAILS_QUOTA"
  },
  relation_sms_send: {
    quotaIcon: "sms",
    productIcon: "logo-satisfaction.png",
    labelKey: "SMS_QUOTA"
  }
};

export default { ROLES, FEATURES, QUOTAS };
